<template>
  <div class="hidden-xs hidden-sm">
    <div id="header-detail">
      <div class="mycontainer">
        <div class="row">
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  mounted () {
    this.init()
  },
  data: function () {
    return {
      data: []
    }
  },
  methods: {
    /**
       * Init data
       * */
    init () {
      var vm = this
      this.axios.getListCategory([], function (response) {
        vm.$session.set('listCategory', response.data.data.items)
        vm.data = response.data.data.items
        for (let r in response.data.data.items) {
          let _menuDeal = {
            slug: response.data.data.items[r].slug,
            uuid: response.data.data.items[r].uuid,
            lower_categories: response.data.data.items[r].lower_categories
          }
          vm.$store.commit('setMenuDeal', _menuDeal)
          break
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
  @import '../../assets/css/combo_details.css';
</style>
