<template>
  <div id="header">
    <div class="mycontainer">
      <div class="row">
        <router-link to="/" class="logo pull-left"></router-link>
        <div class="pull-right t13 text-black disposition-info">
          <!-- <user-top-desktop></user-top-desktop> -->
          <span class="header-location hidden-xs hidden-sm md:border-l  md:pl-10 ml-10 text-black hidden-1150"
            v-if="deliveryInfo.addressInfo"
          >
            <img src="https://static.phdvasia.com/mx/icons/icon-location.svg" class="mr-5 opacity-50" width="13">
              <div v-if="$i18n.locale ===$const.LANG_ES" class="order-info-top" data-synth="order-info-top">
                <span class="t14">{{ deliveryInfo.orderType ==='delivery' ? $t('delivery_to') : $t('take_away')}}</span>
                <router-link to="/" class="cursor-pointer">
                    <label style="" class="order-type-text border-b cursor-pointer">
                        {{deliveryInfo.addressInfo}}
                    </label>
                </router-link>
              </div>
              <div v-else class="order-info-top">
                <router-link to="/" class="cursor-pointer">
                    <span class="order-type-text cursor-pointer" v-if="deliveryInfo.orderType">
                        {{ deliveryInfo.orderType ==='delivery' ? $t('delivery_to') : $t('take_away')}}
                    </span>
                </router-link>
                <router-link to="/" class="cursor-pointer">
                    <label style="" class="order-type-text border-b cursor-pointer">
                        {{deliveryInfo.addressInfo}}
                    </label>
                </router-link>
              </div>

            <!--<router-link to="/" class="cursor-pointer">
                <label
                  style=""
                  class="order-type-text border-b cursor-pointer" v-if="deliveryInfo.orderType">
                    {{$t(deliveryInfo.orderType)}}
                </label>
            </router-link>
            <span v-if="deliveryInfo.orderType ==='delivery'">{{$t('to')}}</span>
            <span v-else>{{$t('from')}}</span>
            <router-link to="/" class="cursor-pointer">
                <label
                  style=""
                  class="order-type-text border-b cursor-pointer">
                    {{deliveryInfo.addressInfo}}
                </label>
            </router-link>-->

          </span>
          <span data-synth="change-collection-time" class="header-time xs-ml-10 cursor-pointer md:border-l md:inline md:pl-10 ml-10"
                v-if="deliveryInfo.orderType"
                @click="showAsapPopup">
                        <img src="https://static.phdvasia.com/mx/icons/clock.svg" width="14">
                        <span class="order-datetime-text border-b text-black" v-html="collectionTime"></span>
                <span v-if="$disposition.getByKey(this, 'advanceOrder', 0) === 0"
                      class="order-datetime-text border-b">
                         {{$t('checkout.waiting_time', {min: getWaitingTime(this)})}}
                </span>
          </span>
          <span v-else></span>

          <!-- <div class="header-basket cursor-pointer show-basket">
            <user-top-mobile></user-top-mobile>
          </div> -->
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>
<script>
import ASAPPopup from '../popup/OrderASAP.vue'
import UserTopMobile from '../partials/UserTopMobile'
import UserTopDesktop from '../partials/UserTopDesktop'

export default {
  components: {
    UserTopMobile,
    UserTopDesktop
  },
  destroyed () {
    this.$root.$off('onReInitHeader')
    this.$root.$off('collectionTimeUpdate')
    this.$root.$off('cartUpdate')
  },
  mounted: function () {
    this.$disposition.init(this)
    this.$cart.init(this)
    this.initdata()
    var vm = this
    this.$root.$on('cartUpdate', () => {
      vm.$cart.init(vm)
    })
    this.$root.$on('collectionTimeUpdate', () => {
      vm.collectionTime = vm.getTimePicker(vm, true)
    })
    this.mode = this.$session.get('modeView') || 'grip'
    this.$root.$on('onReInitHeader', () => {
      vm.initdata()
    })
  },
  data () {
    return {
      mode: 'grip',
      deliveryInfo: this.getDeliveryInfo(this),
      collectionTime: '',
      orderType: null
    }
  },
  methods: {
    initdata () {
      this.collectionTime = this.getTimePicker(this, true)
      this.deliveryInfo = this.getDeliveryInfo(this)
    },
    showAsapPopup () {
      this.$modal.show(ASAPPopup, {parentlink: this.getCurrentPath()}, {class: ''})
    }
  }
}

</script>
