<template>
    <div id="nav" v-if="!mode || mode ==='main' || mode == 'combo-detail' ">
      <div class="mycontainer">
        <template v-if="categoryList && categoryList.length !==0">
          <button v-if="showLeftArrow" class="button-scroll-mobile left" @click="moveTo('left')">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
          </button>
          <button v-else class="button-scroll-mobile right" @click="moveTo('right')">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
        </template>
        <ul id="nav-scroll-mobile">
          <li v-for="(item) in categoryList" v-bind:key="item.uuid" class="catergory-nav-item"
              v-bind:class="{active: $route.name ===$const.HEADER_MENU.CATEGORY && (selectedCategory && item.uuid == selectedCategory.uuid) }"
              v-on:click="changeCategory(item.slug, item)">
            <a href="javascript: void(0)" class="lg-cond" :data-synth="'category-' + item.slug">{{item.name}}</a>
          </li>
          <!--<li v-bind:class="{active: $route.name ===$const.HEADER_MENU.CATEGORY && (selectedCategory && selectedCategory.uuid == $const.COUPON_UUID)}"
              v-on:click="changeCategory('coupons')">
            <a href="javascript: void(0)" class="lg-cond">{{ $t('coupons') }}</a>
          </li>
          <li
            v-bind:class="$route.name ===$const.HEADER_MENU.TOPIC || $route.name == $const.HEADER_MENU.TOPIC_DETAIL ? 'active' : ''"
            v-on:click="redirectTo($const.HEADER_MENU.TOPIC)">
            <a href="javascript: void(0)" class="lg-cond">{{ $t('footer.topics') }}</a>
          </li>-->
        </ul>
        <div
          v-if="$route.name ===$const.HEADER_MENU.CATEGORY && (selectedCategory && selectedCategory.lower_categories)"
          class="flex">
          <div v-for="(item) in selectedCategory.lower_categories"
               v-bind:key="item.uuid"
               v-on:click="changeCategory(item.slug, selectedCategory, item)"
               :class="'sub_category flex-1' + (selectedSubCategory.uuid ===item.uuid ? ' active' : '')">
            <span>{{item.name}}</span></div>
        </div>
      </div>
    </div>
    <div v-else-if="mode ==='footer'">
      <a href="javascript: void(0)" v-for="item in categoryList" class="clear" :key="item.id"
         v-on:click="changeCategory(item.slug, item)"><span>{{ item.name}}</span></a>
    </div>
</template>
<style scoped>
  .sub_category {
    cursor: pointer;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    /*font-family: 'Sharp Sans', Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;*/
    border-top: 1px solid #e9ebee !important;
  }

  .sub_category:not(:last-child) {
    border-right: 1px solid #e9ebee !important;
  }

  .sub_category.active {
    color: #da291c;
  }

  .sub_category:hover {
    color: #da291c;
  }

  @media (max-width: 500px) {
    .sub_category {
      line-height: unset;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center !important;
      -webkit-align-items: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }

    .sub_category span {
      width: 100%;
    }
  }
</style>
<script>
import moment from 'moment'
import { MAPPED_ACTIONS } from '../../utils/map-actions'
import * as action from '../../store/actions'
import {
  mapGetters,
  mapActions
} from 'vuex'

const ModalPopup = () => import('../popup/ModalPizza.vue')
const ModalPopupHalf = () => import('../popup/ModalPizzaHalf.vue')

export default {
  props: ['mode', 'styles', 'navId'],
  data: function () {
    return {
      slug: this.$route.params.slug,
      slugSub: this.$route.params.sub,
      slugParent: this.$route.params.slug,
      uuid: this.$route.params.uuid,
      sub: [],
      active: false,
      hasDisposition: false,
      currentSelectedCategory: null,
      ...MAPPED_ACTIONS,
      showLeftArrow: false,
    }
  },
  computed: {
    ...mapGetters({
      selectedCategoryId: 'category_uuid',
      selectedProduct: 'selectedProduct',
      selectedCartId: 'selectedCartId',
      selectedCategory: 'selectedCategory',
      selectedSubCategory: 'selectedSubCategory',
      selectedCart: 'selectedCart',
      categoryList: 'categoryList',
      selectedDisposition: 'selectedDisposition'
    })
  },
  watch: {
    selectedDisposition: function (newValue, oldValue) {
      if (newValue && !this.empty(newValue) && newValue.outletCode != '') {
        this.hasDisposition = true
      } else {
        this.hasDisposition = false
      }
    },
    '$route' (to, from) {
      this.routeChanged(to, from)
    },
    selectedCategory: function (newValue, oldValue) {
      if (this.selectedSubCategory) {
        this.currentSelectedCategory = this.selectedSubCategory
      } else {
        this.currentSelectedCategory = this.selectedCategory
      }
      // if the select category is final, list menu will be scroll to right and show arrow scroll left
      if (this.selectedCategory.uuid === this.categoryList[this.categoryList.length - 1].uuid) {
        let widthInnerScreen = screen.width;
        let navMobile = document.getElementById('nav-scroll-mobile')
        this.showLeftArrow = true
        setTimeout(() => {
          navMobile.scrollLeft += widthInnerScreen;
        }, 800)
        
      }
    },
    selectedSubCategory: function (newValue, oldValue) {
      if (this.selectedSubCategory) {
        this.currentSelectedCategory = this.selectedSubCategory
      } else {
        this.currentSelectedCategory = this.selectedCategory
      }
    },
    selectedProduct: function (newValue, oldValue) {
      this.showPopup(this.selectedProduct, this.currentSelectedCategory)
    },
    selectedCart: function (selectedCart, oldValue) {
      if (selectedCart) {
        let vm = this
        let category = getCategory(selectedCart.cartId, vm.categoryList)

        vm.getProduct(selectedCart.productUuid, selectedCart.isHalf, selectedCart.isLunch, function (res, err) {
          vm.setSelectedProduct(res)
        })
      }
    }
  },
  created () {
    let vm = this
    this.routeChanged(this.$route)
  },
  mounted () {
    let vm = this
    this.hasDisposition = this.checkPosition(this)

    if (this.$route.params.sub) {
      this.slug = this.$route.params.sub
    }

    this.$root.$on('navchanged', (slug) => {
      this.slug = slug
    })
  },
  methods: {
    ...mapActions({
      setSelectedProduct: action.SET_SELECTED_PRODUCT,
      setSelectedCategory: action.SET_SELECTED_CATEGORY,
      setSelectedSubCategory: action.SET_SELECTED_SUB_CATEGORY
    }),
    routeChanged (to = null, from = null) {
      let vm = this
      let subSlug = to.params.sub
      let parentSlug = to.params.slug
      let uuid = to.params.uuid

      vm.slug = to.params.slug
      vm.slugParent = vm.slug

      if (to.params.sub) {
        vm.slugSub = to.params.sub
        vm.slug = to.params.sub
      }

      if (to.name === vm.$const.HEADER_MENU.CATEGORY || to.name == vm.$const.HEADER_MENU.COMBO_DETAIL) {
        if (subSlug || uuid) {
          let isHalf = (subSlug === vm.$const.HEADER_MENU.HALF_HAlF || (uuid == vm.$const.HEADER_MENU.HALF_HAlF))
          let param = uuid || subSlug
          let onlyLunch = vm.$const.HALF_HALF_SET.NORMAL

          if (to.params.uuid === vm.$const.HEADER_MENU.LUNCH_SET) {
            onlyLunch = vm.$const.HALF_HALF_SET.LUNCH
          }

          vm.getProduct(param, isHalf, onlyLunch, function (res, err) {
            if (res) {
              vm.setSelectedProduct(res)
            }
          })
        }
      }
    },
    getProduct (uuid, isHalf = false, onlyLunch = false, callback) {
      let vm = this

      if ((this.selectedSubCategory && _.lowerCase(uuid) === _.lowerCase(this.selectedSubCategory.slug)) ||
          (this.selectedCategory && _.lowerCase(uuid) === _.lowerCase(this.selectedCategory.slug))) {
        return
      }

      if (isHalf) {
        let res = {}
        res.uuid = moment().format('x')
        res.isHalf = true
        res.onlyLunch = onlyLunch
        callback(res)
      } else {
        vm.getProductByUUID(uuid, function (res, err) {
          callback(res, err)
        })
      }
    },
    changeCategory (slug, category, subCategory = null) {
      this.$store.dispatch('setUserAction', this.SELECT_CATEGORY)

      let vm = this
      let subSlug = null
      let selectedCategory = category
      let selectedSubCategory = subCategory

      if (!subCategory) {
        if (category && category.lower_categories && category.lower_categories.length) {
          subSlug = category.lower_categories[0].slug
          selectedSubCategory = category.lower_categories[0]
        }
      } else {
        subSlug = selectedSubCategory.slug
      }

      if (slug === vm.$const.HEADER_MENU.COUPON) {
        selectedCategory = {
          display_type: {web: 4, mobile: 2},
          lower_categories: [],
          name: vm.$t('coupons'),
          only_lunch: 0,
          piece: 1,
          slug: slug,
          uuid: vm.$const.COUPON_UUID
        }
      }

      vm.setSelectedCategory(selectedCategory)
      vm.setSelectedSubCategory(selectedSubCategory)

      if (subSlug) {
        vm.redirectToMenuPage(selectedCategory.slug, subSlug)
      } else {
        vm.redirectToMenuPage(selectedCategory.slug)
      }
    },
    showPopup (product = null) {
      let vm = this
      if (product) {
        let popup = product.isHalf ? ModalPopupHalf : ModalPopup
        let params = {
          menu: product,
          scrollable: false,
          mfirst: {},
          msecond: {},
          extraCheese: []
        }

        if (product.isHalf) {
          params.onlyLunch = product.onlyLunch
        }

        vm.$modal.show(popup, params, {
          class: 'modal-menu',
          clickToClose: false,
          name: 'close-modal-menu'
        }, {
          'before-open': function (event) {
            document.body.classList.add('modal-open')
          },
          'closed': function (event) {
            vm.$root.$emit('onTrackingCategory')
          },
          'opened': function (event) {
          }
        })
      }
    },
    moveTo(direction) {
      let widthInnerScreen = screen.width;
      let navMobile = document.getElementById('nav-scroll-mobile')
      if (direction === 'left') {
        navMobile.scrollLeft -= widthInnerScreen;
        this.showLeftArrow = false
      } else if (direction === 'right') {
        navMobile.scrollLeft += widthInnerScreen;
        this.showLeftArrow = true
      }
    }
  }
}

function getCategory (uuid, categoryList) {
  for (let iCategory in categoryList) {
    if (categoryList[iCategory].uuid === uuid) {
      return categoryList[iCategory]
    } else {
      let lowerCategories = categoryList[iCategory].lower_categories

      if (lowerCategories && lowerCategories.length) {
        for (let iSubCategory in lowerCategories) {
          if (lowerCategories[iSubCategory].uuid === uuid) {
            return lowerCategories[iSubCategory]
          }
        }
      }
    }
  }

  return null
}

</script>
