<template>
  <div id="user-top-desktop" class="info-box border-radius-bottom hidden-xs hidden-sm">
    <div class="login-logo">
      <img v-if="!$auth.check()" src="https://static.phdvasia.com/mx/icons/inactive@2x.png" class="" width="22">
      <img src="https://static.phdvasia.com/mx/icons/active@2x.png" width="23" v-else>
    </div>
    <div class="login-box">
      <span v-if="$auth.check() ">
        <router-link to="/profile" v-html="$t('full_name_format', {first_name:$auth.user().first_name, last_name:$auth.user().last_name})"></router-link>
      </span>
      <router-link v-else to="/login">
        {{ $t("login_title") }}
      </router-link>
      <span>
        <router-link to="/register" v-if="!$auth.check()">/ {{ $t("login_register")}}</router-link>
        <button v-else v-on:click="$root.$emit('logout')">/ {{ $t("button_logout")}}</button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  mounted: function () {
  },
  data () {
    return {}
  },
  methods: {}
}

</script>
