<template>
  <div>
    <!--<combo-header></combo-header>-->
    <header-main></header-main>
    <nav-category mode="main"></nav-category>
    <combo-detail></combo-detail>
  </div>
</template>
<style lang='scss' >
  @import '../assets/css/style.css';
  @import '../assets/css/home.css';
  @import '../assets/css/category.css';
</style>
<script>
import ComboHeader from '../components/combo/ComboHeader.vue'
import ComboDetail from '../components/combo/ComboDetail.vue'
import HeaderMain from '../components/partials/HeaderMain.vue'
import NavCategory from '../components/partials/NavCategory.vue'

export default {
  mounted () {
    document.body.classList.remove('mainpage')
    document.body.classList.add('mainpage-combo')
  },
  components: {
    ComboHeader,
    ComboDetail,
    HeaderMain,
    NavCategory
  }
}
</script>
