<template>
  <div id="user-top-mobile" class="hidden-lg hidden-md border-radius-bottom">
    <div class="hidden-lg hidden-md border-radius-bottom login-box dropdown-lang" style="z-index: 990;">
      <dropdown menu-right>
        <btn class="dropdown-toggle dropdown-toggle-login">
          <img v-if="!$auth.check()" src="https://static.phdvasia.com/mx/icons/inactive@2x.png" class="" width="25">
          <img src="https://static.phdvasia.com/mx/icons/active@2x.png" class="mr-5" width="25" v-else>
          <span class="caret"></span></btn>
        <template slot="dropdown">
          <li @click="changeLang('pt')">
            <router-link  v-if="$auth.check() " to="/profile" v-html="$t('full_name_format', {first_name:$auth.user().first_name, last_name:$auth.user().last_name})"></router-link>
            <router-link  v-else to="/login">
            {{ $t("login_title") }}
            </router-link>
          </li>
          <li>
            <router-link to="/tracking">{{ $t("track_your_order")}}</router-link>
          </li>
          <li>
            <router-link  to="/register" v-if="!$auth.check()">{{ $t("login_register")}}</router-link>
            <a v-else  v-on:click="logout">{{ $t("button_logout")}}</a>
          </li>
        </template>
      </dropdown>
    </div>
  </div>
</template>
<style scoped>
  .login-box a{
    padding: 0px 5px !important;
  }
  .dropdown-toggle-login{
    border: none;
    padding: 10px 14px;
  }
  .mainpage .dropdown-toggle-login{
    padding: 2px 14px;
  }

  #user-top-mobile {
    position: absolute;
    right: 0;
    min-width: 80px;
    z-index: 201;
    top: 5px;
  }
  .login-logo {
    text-align: center;
    line-height: 38px;
    width: 100px;
    margin: auto;

  }
  .login-box {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    line-height: 20px;
    background: #fff;
    padding: 0px 5px 5px 5px;
    border-bottom-left-radius: 4px;
    z-index: 999;
  }
  .login-box a, .login-box span, .login-box button {
    text-decoration: none;
    color: #333;
    font-weight: 600;
  }
</style>
<script>
export default {
  mounted: function () {
  },
  data () {
    return {
      flag: false
    }
  },
  methods: {
    toggleLogin () {
      this.flag = !this.flag
    },
    logout: function () {
      this.$auth.logout({
        data: {
          token: this.$auth.user().token
        },
        success: function (response) {
          if (response.data.status) {
            this.$router.push('/')
          } else {
            main.$notify({
              type: 'Error',
              title: response.data.error.msg
            })
          }
        },
        error: function (data) {
          main.$notify({
            type: 'Error',
            title: this.$t('message_logout_error')
          })
        }
      })
    }
  }
}

</script>
